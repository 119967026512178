// src/components/pages/client/InvoicePDF.js
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Stiluri de bază pentru PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
});

// Componenta de PDF
const InvoicePDF = ({ invoice }) => (
  <Document>
    <Page style={styles.page}>
      <Text style={styles.title}>Invoice</Text>
      
      <View style={styles.section}>
        <Text style={styles.text}>Load: {invoice.load}</Text>
        <Text style={styles.text}>Broker: {invoice.brokerName}</Text>
        <Text style={styles.text}>Invoice Number: {invoice.invoiceNumber}</Text>
        <Text style={styles.text}>Rate: ${invoice.rate}</Text>
        <Text style={styles.text}>Driver: {invoice.driverName}</Text>
        <Text style={styles.text}>Pickup Time: {invoice.pickupTime}</Text>
        <Text style={styles.text}>Delivery Time: {invoice.deliveryTime}</Text>
      </View>

      {/* Alte detalii pe care dorești să le adaugi în factură */}
    </Page>
  </Document>
);

export default InvoicePDF;
