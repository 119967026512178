import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Polyline, Marker } from '@react-google-maps/api';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';

const googleMapsApiKey = 'AIzaSyANTFgVt0YuYOHPbMRNYO3r09Zu0j73yXY';

const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
};

const infoContainerStyle = {
  position: 'absolute',
  top: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: '10px',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
};

const ShareTrucking = () => {
  const { driverId, expires } = useParams();
  const [route, setRoute] = useState([]);
  const [eta, setEta] = useState('N/A');
  const [distance, setDistance] = useState('N/A');
  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  const [currentLocation, setCurrentLocation] = useState(null); 
  const [socket, setSocket] = useState(null); 
  const [isExpired, setIsExpired] = useState(false);  // Adaugă o stare pentru expirare

  // Conectează-te la WebSocket pentru actualizări în timp real
  useEffect(() => {
    const newSocket = io('https://api-backend.full-trucking-service.com', {
      transports: ['websocket', 'polling'],
      secure: true,  // Asigură conexiune securizată
      reconnect: true, // Activează reconectarea automată
      timeout: 20000, // Timpul maxim de conectare înainte de timeout
      reconnectionAttempts: 5, // Numărul de încercări de reconectare înainte de a renunța
      reconnectionDelay: 1000, // Întârziere între reconectări
      rejectUnauthorized: false // Permite conexiuni securizate fără a respinge cererile nesemnate
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    newSocket.on('locationUpdated', (data) => {
      if (data.driverId === driverId && data.location) {
        setCurrentLocation({ lat: data.location.lat, lng: data.location.lng });
      }
    });

    newSocket.on('cancelRoute', (data) => {
      if (data.driverId === driverId) {
        setIsExpired(true);  // Dacă ruta este anulată, setăm starea ca expirată
      }
    });

    return () => {
      newSocket.close(); 
    };
  }, [driverId]);


  // Fetch route details from backend using driverId
  useEffect(() => {
    const fetchRoute = async () => {
      try {
        const response = await fetch(`https://api-backend.full-trucking-service.com/api/activeRoute/${driverId}`);
        if (response.ok) {
          const data = await response.json();

          // Verifică dacă ruta a fost anulată sau linkul a expirat
          if (!data.route || data.expiresAt && Date.now() > data.expiresAt) {
            setIsExpired(true);
            return;
          }

          setRoute(data.route);
          setEta(data.eta);
          setDistance(data.distance);

          if (data.route.length > 0) {
            setCurrentLocation({
              lat: data.route[0].lat,
              lng: data.route[0].lng,
            });
            setMapCenter({
              lat: data.route[0].lat,
              lng: data.route[0].lng,
            });
          }
        } else {
          console.error('Failed to fetch route');
        }
      } catch (error) {
        console.error('Error fetching route:', error);
      }
    };

    // Ensure link is not expired
    if (Date.now() < expires) {
      fetchRoute();
    } else {
      setIsExpired(true);
    }
  }, [driverId, expires]);

  if (isExpired) {
    // Dacă linkul este expirat sau ruta este anulată, afișăm mesajul de expirare
    return (
      <div style={infoContainerStyle}>
        <p>Link has expired.</p>
      </div>
    );
  }

  return (
    <div className="shareTruckingSection">
      {/* Container pentru ETA și miles */}
      <div style={infoContainerStyle}>
        <p>Estimated Time: {eta}</p>
        <p>Distance: {distance}</p>
      </div>

      <div className="mapContainer">
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap mapContainerStyle={mapContainerStyle} center={mapCenter} zoom={8}>
            {currentLocation && (
              <Marker position={currentLocation} label={`Current Location`} />
            )}
            {route.length > 0 && (
              <>
                <Polyline
                  path={[currentLocation, ...route.map((point) => ({ lat: point.lat, lng: point.lng }))]}
                  options={{ strokeColor: 'blue' }}
                />
                {route.map((point, index) => (
                  <Marker
                    key={index}
                    position={{ lat: point.lat, lng: point.lng }}
                    icon={{ url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }}
                    title={`Point ${index + 1}: ${point.note}, ${point.address}`}
                  />
                ))}
              </>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default ShareTrucking;
