import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/ForgetPasswordPage.module.css';

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com/api';
      await axios.post(`${apiUrl}/forgot-password`, { email });
      setMessage('A password reset link has been sent to your email.');
    } catch (err) {
      console.error('Error sending reset password email:', err);
      setError('Failed to send reset email. Please try again.');
    }
  };

  return (
    <div className={styles.forgetPasswordPage}>
      <div className={styles.logoforgetPasswordPage} onClick={() => navigate('/')}>
        <span className={styles.logoPart1forgetPasswordPage}>Full</span> <span className={styles.logoPart2forgetPasswordPage}>Trucking</span> <span className={styles.logoPart1forgetPasswordPage}>Service</span>
      </div>
      <h1 className={styles.titleforgetPasswordPage}>Forget Password</h1>
      <form onSubmit={handleSubmit} className={styles.formforgetPasswordPage}>
        <div className={styles.formGroupforgetPasswordPage}>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className={styles.inputforgetPasswordPage} />
        </div>
        <button type="submit" className={styles.buttonforgetPasswordPage}>Submit</button>
      </form>
      {message && <p className={styles.successMessage}>{message}</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default ForgetPasswordPage;
