import React from 'react';
import styles from '../styles/FaqPage.module.css'; // Assuming you're using CSS modules

const FaqPage = () => {
  return (
    <div className={styles.faqPage}>
      <h1>Frequently Asked Questions (FAQ)</h1>

      {/* Dashboard Section */}
      <section className={styles.faqSection}>
        <h2>Dashboard</h2>
        <p>
          The Dashboard is the main interface where you can see an overview of all your operations. You can track ongoing loads, view recent driver activities, manage fuel tax reporting, and monitor financial reports. The Dashboard gives you a quick and comprehensive view of your company’s current status.
        </p>
      </section>

      {/* Finance Section */}
      <section className={styles.faqSection}>
        <h2>Finance</h2>
        <p>In the Finance section, you can manage all aspects related to your company’s financial operations. This section includes:</p>
        <ul>
          <li>
            <strong>Ledger Manager</strong>: This is where you can record and view all your financial transactions. It allows you to keep a detailed ledger of income, expenses, and other financial activities.
          </li>
          <li>
            <strong>Payroll</strong>: The Payroll section is where you manage driver payments. You can calculate wages based on loads completed, miles driven, and other criteria. Here, you can also generate payroll reports for each driver.
          </li>
        </ul>
      </section>

      {/* Operations Section */}
      <section className={styles.faqSection}>
        <h2>Operations</h2>
        <p>In the Operations section, you can manage your drivers, fleet, brokers, shippers, and consignees. Here's how each subsection works:</p>
        <ul>
          <li>
            <strong>Drivers</strong>: To add a new driver, go to Operations &gt; Drivers and fill out all the necessary details such as first name, last name, truck number, etc. Once added, the driver will appear in the "Existing Drivers" section, where you can edit or delete their information.
          </li>
          <li>
            <strong>Fleet</strong>: Manage your fleet by adding new trucks, trailers, or vehicles. Go to Operations &gt; Fleet, fill out all necessary fields, and save the details. You can edit or delete existing fleet information in the "Existing Fleet" section.
          </li>
          <li>
            <strong>Brokers, Shippers, Consignees</strong>: These work similarly to Drivers and Fleet. Go to Operations &gt; Brokers (or Shippers, Consignees) to add new entries. Fill in the required fields and save the information. Existing entries can be edited or deleted from their respective "Existing" sections.
          </li>
        </ul>
      </section>

      {/* Logistics Section */}
      <section className={styles.faqSection}>
        <h2>Logistics</h2>
        <p>The Logistics section helps you manage your loads and track shipments. Here's how it works:</p>
        <ul>
          <li>
            <strong>Load Scheduler</strong>: This is where you plan and schedule loads for your drivers. You can assign loads to specific drivers and track the status of each load.
          </li>
          <li>
            <strong>Load Tracker</strong>: Use this to monitor the progress of each load in real-time. The tracker gives you details such as the current location of the truck, estimated time of arrival, and other relevant details.
          </li>
        </ul>
      </section>

      {/* Tracking Section */}
      <section className={styles.faqSection}>
        <h2>Tracking</h2>
        <p>
          The Tracking section allows you to view the real-time location of your drivers and loads. You can also track specific routes taken by drivers and monitor their progress. The tracking data is updated in real-time and can be shared with brokers or consignees if needed.
        </p>
      </section>

      {/* Communication Center Section */}
      <section className={styles.faqSection}>
        <h2>Communication Center</h2>
        <p>
          The Communication Center is where you can chat with drivers, brokers, shippers, and consignees. You can share documents, send real-time messages, and receive updates from your drivers directly through this section. Communication with all stakeholders is managed efficiently from one place.
        </p>
      </section>

      {/* Settings Section */}
      <section className={styles.faqSection}>
        <h2>Settings</h2>
        <p>
          In the Settings section, you can configure company-wide settings such as managing user roles, updating company details, setting payment options, and customizing your platform (e.g., changing themes, logos, etc.). You can also manage your account subscription from this section.
        </p>
      </section>

      {/* Load Entry Section */}
      <section className={styles.faqSection}>
        <h2>Load Entry</h2>
        <p>
          To add a new load, go to the Load Entry section. Here you can input all the details regarding the load, such as the shipper, consignee, pickup and delivery dates, cargo description, weight, and more. Once the load is added, it can be scheduled in the Load Scheduler and tracked in the Load Tracker. 
        </p>
      </section>

      {/* Fuel Tax Reporting Section */}
      <section className={styles.faqSection}>
        <h2>Fuel Tax Reporting</h2>
        <p>
          The Fuel Tax Reporting section helps you manage and report your fuel purchases and consumption. You can add details such as fuel purchased, fuel prices, miles driven, and calculate the fuel taxes for different states. This section ensures compliance with IFTA (International Fuel Tax Agreement) regulations.
        </p>
      </section>

      {/* Logout Section */}
      <section className={styles.faqSection}>
        <h2>Logout</h2>
        <p>
          To log out of the system, simply click on the "Logout" button located in the header or menu. This will safely log you out of the system, ensuring that your data is secure.
        </p>
      </section>
    </div>
  );
};

export default FaqPage;
