import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/LoginPage.module.css';

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [clientId, setClientId] = useState('');
  const [role, setRole] = useState('client'); // Rolul selectat pentru autentificare

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com/api';
  
      // Setăm endpoint-ul în funcție de rol
      const loginEndpoint = role === 'client' 
        ? '/loginClient' 
        : role === 'dispatcher' 
        ? '/loginDispatcher' 
        : '/loginAccounting';
  
      const response = await axios.post(
        `${apiUrl}${loginEndpoint}`,
        { email, password },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.message === '2FA required') {
        setIsTwoFactorRequired(true);
        setClientId(response.data.clientId);
      } else if (response.data.message === 'Login successful') {
        const { token, clientId, package: clientPackage, accessibleModules } = response.data;
  
        sessionStorage.setItem('token', token);
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('role', role);
  
        // Verificăm dacă `clientPackage` este disponibil și corect
        if (clientPackage) {
          if (role === 'client') {
            localStorage.setItem('clientPackage', clientPackage);
            navigateToPackage(clientPackage);
          } else {
            // Dispatcher sau Accounting - redirecționare la pagina de client conform pachetului
            navigate(`/client/${clientPackage}`, { state: { role, accessibleModules } });
          }
        } else {
          setError('Client package is undefined. Please check your account.');
        }
      } else {
        setError('Incorrect email or password.');
      }
    } catch (err) {
      console.error('Error during login:', err);
      const errorMessage = err.response?.data?.error || 'An error occurred. Please try again.';
      setError(errorMessage);
    }
  };
  

  const handleTwoFactorSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com/api';

      if (!clientId || !twoFactorCode) {
        setError('Client ID and 2FA code are required.');
        return;
      }

      const response = await axios.post(
        `${apiUrl}/verify-two-factor`,
        { clientId, code: twoFactorCode },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.message === '2FA verified successfully') {
        const { token, package: clientPackage, accessibleModules } = response.data;

        sessionStorage.setItem('token', token);

        if (clientPackage) {
          if (role === 'client') {
            localStorage.setItem('clientPackage', clientPackage);
            navigateToPackage(clientPackage);
          } else {
            navigate(`/client/${clientPackage}`, { state: { role, accessibleModules } });
          }
        } else {
          setError('Client package is undefined. Please check your account.');
        }
      } else {
        setError('Invalid 2FA code.');
      }
    } catch (err) {
      console.error('Error during 2FA verification:', err);
      const errorMessage = err.response?.data?.error || 'An error occurred. Please try again.';
      setError(errorMessage);
    }
  };

  const navigateToPackage = (clientPackage) => {
    switch (clientPackage) {
      case 'Basic':
        navigate('/client/basic');
        break;
      case 'Standard':
        navigate('/client/standard');
        break;
      case 'Premium':
        navigate('/client/premium');
        break;
      case 'Ultimate':
        navigate('/client/ultimate');
        break;
      default:
        setError('Invalid package.');
    }
  };

  return (
    <div className={styles.loginPageClient}>
      <div className={styles.logoLoginClient} onClick={() => navigate('/')}>
        <span className={styles.logoPart1Client}>Full</span> <span className={styles.logoPart2Client}>Trucking</span> <span className={styles.logoPart1Client}>Service</span>
      </div>
      <h1 className={styles.titleLoginClient}></h1>
      
      {!isTwoFactorRequired ? (
        <form onSubmit={handleLogin} className={styles.formLoginCLient}>
          <select value={role} onChange={(e) => setRole(e.target.value)} className={styles.inputLoginCLient}>
            <option value="client">Client</option>
            <option value="dispatcher">Dispatcher</option>
            <option value="accounting">Accounting</option>
          </select>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.inputLoginCLient}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.inputLoginCLient}
          />
          {error && <p className={styles.errorMessageLoginClient}>{error}</p>}
          <button type="submit" className={styles.buttonLoginClient}>Login</button>
        </form>
      ) : (
        <form onSubmit={handleTwoFactorSubmit} className={styles.formLoginCLient}>
          <input
            type="text"
            placeholder="Enter Email Code"
            value={twoFactorCode}
            onChange={(e) => setTwoFactorCode(e.target.value)}
            required
            className={styles.inputLoginCLient}
          />
          {error && <p className={styles.errorMessageLoginClient}>{error}</p>}
          <button type="submit" className={styles.buttonLoginClient}>Verify 2FA</button>
        </form>
      )}

      <div className={styles.linksLoginClient}>
        <div onClick={() => navigate('/pricing')} className={styles.buttonLinkLoginClient}>Register</div>
        <div onClick={() => navigate('/forget-password')} className={styles.buttonLinkLoginClient}>Forget Password</div>
      </div>
    </div>
  );
};

export default LoginPage;
