import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/PricingPage.module.css'; // Importing the CSS module

const PricingPage = () => {
  const navigate = useNavigate();
  
  // State pentru secțiuni "See More"
  const [openSection, setOpenSection] = useState(null);

  const handleSelectPlan = (plan) => {
    navigate('/register', { state: { plan } });
  };

  // Funcția de toggle pentru secțiunea See More
  const toggleSeeMore = (plan) => {
    if (openSection === plan) {
      setOpenSection(null); // Închide secțiunea dacă e deschisă
    } else {
      setOpenSection(plan); // Deschide secțiunea
    }
  };

  return (
    <div className={styles.pricingPage}>
      <div className={styles.logoPricing} onClick={() => navigate('/login')}>
        <span className={styles.logoPart1Pricing}>Full</span> <span className={styles.logoPart2Pricing}>Trucking</span> <span className={styles.logoPart1Pricing}>Service</span>
      </div>
      <h1 className={styles.titlePricing}>Choose your package</h1>
      <div className={styles.plansPricing}>

        {/* Basic Plan */}
        <div className={styles.planPricing}>
          <h2>Basic</h2>
          <p>All you need for a small company</p>
          <ul>
            <li>Add Loads, Drivers, Brokers, etc.</li>
            <li>Manage Driver Pay and download reports</li>
          </ul>
          <div className={styles.pricePricing}>Monthly: €39.99</div>
          <div className={styles.priceAnnualPricing}>Annually: €29.99/month</div>
          <button onClick={() => handleSelectPlan('Basic')}>Select Basic</button>
          <button onClick={() => toggleSeeMore('Basic')}>See More</button>
          {openSection === 'Basic' && (
            <div className={styles.moreInfo}>
              <p>Basic package is perfect for small transportation companies just starting. You can manage your loads, drivers, and brokers easily. Generate driver pay reports and ensure smooth payroll processing. Ideal for companies with 1-5 trucks.</p>
              <p>Features included:</p>
              <ul>
                <li>Driver pay tracking</li>
                <li>Basic load management</li>
                <li>Broker and shipper management</li>
                <li>Limited support</li>
              </ul>
            </div>
          )}
        </div>

        {/* Standard Plan */}
        <div className={styles.planPricing}>
          <h2>Standard</h2>
          <p>Includes everything from Basic plus more</p>
          <ul>
            <li>Add your company logo to Driver Pay PDFs</li>
            <li>Add an extra accountant</li>
          </ul>
          <div className={styles.pricePricing}>Monthly: €59.99</div>
          <div className={styles.priceAnnualPricing}>Annually: €49.99/month (1 month free)</div>
          <button onClick={() => handleSelectPlan('Standard')}>Select Standard</button>
          <button onClick={() => toggleSeeMore('Standard')}>See More</button>
          {openSection === 'Standard' && (
            <div className={styles.moreInfo}>
              <p>The Standard package offers more flexibility for growing companies. You can add your logo to all documents and have the option to add another accountant, ensuring a more efficient financial workflow.</p>
              <p>Features included:</p>
              <ul>
                <li>Everything in Basic</li>
                <li>Company branding on PDF reports</li>
                <li>Additional accountant access</li>
                <li>Priority customer support</li>
              </ul>
            </div>
          )}
        </div>

        {/* Premium Plan */}
        <div className={styles.planPricing}>
          <h2>Premium</h2>
          <p>Includes everything from Standard plus IFTA management</p>
          <ul>
            <li>Upload PDF reconfirmations directly</li>
            <li>Get 3 additional accountants</li>
          </ul>
          <div className={styles.pricePricing}>Monthly: €79.99</div>
          <div className={styles.priceAnnualPricing}>Annually: €69.99/month (2 months free)</div>
          <button onClick={() => handleSelectPlan('Premium')}>Select Premium</button>
          <button onClick={() => toggleSeeMore('Premium')}>See More</button>
          {openSection === 'Premium' && (
            <div className={styles.moreInfo}>
              <p>The Premium package is designed for medium-sized businesses that need advanced reporting features and additional accountants for better financial management. Manage your IFTA fuel tax reporting efficiently and streamline your document uploads with this plan.</p>
              <p>Features included:</p>
              <ul>
                <li>Everything in Standard</li>
                <li>IFTA reporting tools</li>
                <li>Direct PDF uploads</li>
                <li>3 additional accountant accesses</li>
                <li>Advanced support</li>
              </ul>
            </div>
          )}
        </div>

        {/* Ultimate Plan */}
        <div className={styles.planPricing}>
          <h2>Ultimate</h2>
          <p>Everything from Premium plus mobile app for drivers</p>
          <ul>
            <li>Communicate via chat, share documents</li>
            <li>Track and share location with shippers</li>
          </ul>
          <div className={styles.pricePricing}>Monthly: €99.99</div>
          <div className={styles.priceAnnualPricing}>Annually: €89.99/month (3 months free)</div>
          <button onClick={() => handleSelectPlan('Ultimate')}>Select Ultimate</button>
          <button onClick={() => toggleSeeMore('Ultimate')}>See More</button>
          {openSection === 'Ultimate' && (
            <div className={styles.moreInfo}>
              <p>The Ultimate package provides everything a large transportation company could need, including mobile app integration for drivers, real-time location sharing with clients, and enhanced communication features. Ideal for companies with a large fleet and extensive operations.</p>
              <p>Features included:</p>
              <ul>
                <li>Everything in Premium</li>
                <li>Mobile app for drivers with chat and document sharing</li>
                <li>Location tracking and sharing with shippers and brokers</li>
                <li>Real-time notifications</li>
                <li>Unlimited accountant access</li>
                <li>Premium support 24/7</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
