import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/ResetPasswordPage.module.css';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(`https://api-backend.full-trucking-service.com/api/reset-password/${token}`, {
        password: newPassword,
      });

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => navigate('/login'), 3000); // Redirecționează la login după 3 secunde
      } else {
        setError(response.data.message || 'Password reset failed.');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
    }
  };

  return (
    <div className={styles.resetPasswordPage}>
      <h1>Reset Password</h1>
      {success ? (
        <p className={styles.success}>Password reset successfully! Redirecting to login...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {error && <p className={styles.error}>{error}</p>}
          <button type="submit">Reset Password</button>
        </form>
      )}
    </div>
  );
};

export default ResetPasswordPage;
