import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import { Bar, Pie, Scatter } from 'react-chartjs-2';
import GaugeChart from 'react-gauge-chart';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Picker from 'emoji-picker-react'; 
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import styles from '../../styles/BasicPage.module.css';





const apiUrl = process.env.REACT_APP_API_URL || 'https://api-backend.full-trucking-service.com';


const BasicPage = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(null);
  const [userSettings, setUserSettings] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [consignees, setConsignees] = useState([]);
  const [loads, setLoads] = useState([]);
  const [driverPays, setDriverPays] = useState([]);
  const [loadNumber, setLoadNumber] = useState('');
  const [dispatcher, setDispatcher] = useState('');
  const [brokerName, setBrokerName] = useState('');
  const [brokerAddress, setBrokerAddress] = useState('');
  const [rate, setRate] = useState('');
  const [equipment, setEquipment] = useState('');
  const [driver, setDriver] = useState('');
  const [truck, setTruck] = useState('');
  const [status, setStatus] = useState('');
  const [shipperName, setShipperName] = useState('');
  const [shipperAddress, setShipperAddress] = useState('');
  const [consigneeName, setConsigneeName] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');
  const [miles, setMiles] = useState('');
  const [dh, setDH] = useState('');
  const [notes, setNotes] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [messages, setMessages] = useState([]);
  const [startDate, setStartDate] = useState('');  // Definim startDate
  const [endDate, setEndDate] = useState('');     
  
  const fetchData = async () => {
    try {
      const config = { withCredentials: true };
  
      const [
        userSettingsRes,
        driversRes,
        trucksRes,
        brokersRes,
        shippersRes,
        consigneesRes,
        loadsRes,
        driverPaysRes,
        
      ] = await Promise.all([
        axios.get(`${apiUrl}/settings`, config),
        axios.get(`${apiUrl}/drivers`, config),
        axios.get(`${apiUrl}/trucks`, config),
        axios.get(`${apiUrl}/brokers`, config),
        axios.get(`${apiUrl}/shippers`, config),
        axios.get(`${apiUrl}/consignees`, config),
        axios.get(`${apiUrl}/loads`, config),
        axios.get(`${apiUrl}/driverpays`, config),
     
      ]);
  
      setUserSettings(userSettingsRes.data);
      setDrivers(driversRes.data);
      setTrucks(trucksRes.data);
      setBrokers(brokersRes.data);
      setShippers(shippersRes.data);
      setConsignees(consigneesRes.data);
      setLoads(loadsRes.data);
      setDriverPays(driverPaysRes.data);
  
    

   
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleSendMessage = (message) => {
    if (message.trim() !== '') {
      setMessages((prevMessages) => [
        ...prevMessages,
        { from: 'me', message },
      ]);
    }
  };

  
  
  
  

  useEffect(() => {
    fetchData();
  }, []);
  const handleUpdateDriver = async (updatedDriver) => {
    try {
      const response = await axios.put(`${apiUrl}/drivers/${updatedDriver._id}`, updatedDriver, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
  
      if (!response || response.status !== 200) {
        throw new Error('Failed to update driver');
      }
  
      const updatedData = response.data;
      setDrivers((prevDrivers) =>
        prevDrivers.map((driver) => (driver._id === updatedDriver._id ? updatedData : driver))
      );
      alert('Driver information has been updated successfully!');
    } catch (error) {
      console.error('Error updating driver:', error);
      alert('Failed to update driver');
    }
  };
  
  const openSection = (section) => setActiveSection(section);
  const closeSection = () => setActiveSection(null);

  const handleLogout = async () => {
    try {
      await axios.post(`${apiUrl}/logout`, {}, { withCredentials: true });
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const updateUserSettings = async (settings) => {
    try {
      const config = { headers: { 'Content-Type': 'application/json' }, withCredentials: true };
      const response = await axios.put(`${apiUrl}/user-settings`, settings, config);
      setUserSettings(response.data);
    } catch (error) {
      console.error('Error updating user settings:', error);
    }
  };

  const handleAddLoad = async (load) => {
    try {
        if (!load.loadNumber || !load.brokerName || !load.brokerAddress || !load.rate || !load.miles || !load.dh) {
            alert('Please fill in all required fields.');
            return;
        }

        // Elimina virgula și convertește rate într-un număr
        const cleanedRate = parseFloat(load.rate.replace(/,/g, ''));
        
        const loadData = {
            ...load,
            rate: cleanedRate,  // Asigură-te că `rate` este un număr
            miles: parseFloat(load.miles), // Adaugă miles și convertește în număr
            dh: parseFloat(load.dh), // Adaugă dh și convertește în număr
        };

        const config = { headers: { 'Content-Type': 'application/json' }, withCredentials: true };
        const response = await axios.post(`${apiUrl}/loads`, loadData, config);

        setLoads([...loads, response.data]);
    } catch (error) {
        console.error('Error adding load:', error);
    }
};

  
  
  
const handleAddDriverPay = async (driverPayData) => {
  try {
    const response = await axios.post('http://localhost:5000/api/driverpays', driverPayData, {
      withCredentials: true, // Dacă folosești autentificare
    });
    console.log('Driver pay added:', response.data);
  } catch (error) {
    console.error('Error adding driver pay:', error);
  }
};


  
 
  

  return (
    <div className={styles.ultimatePage}>
      <header className={styles.headerultimatePage}>
        <div className={styles.logoultimatePage} onClick={() => navigate('')}>
          <span className={styles.logoPart1ultimatePage}>Full</span>{' '}
          <span className={styles.logoPart2ultimatePage}>Dispatch</span>{' '}
          <span className={styles.logoPart1ultimatePage}>Service</span>
        </div>
        <nav className={styles.navultimatePage}>
          <ul className={styles.mainMenuultimatePage}>
       
            <li>
            Finance
              <ul className={styles.subMenuultimatePage}>
                <li onClick={() => openSection('accounting-manager')}>Ledger Manager</li>
                <li onClick={() => openSection('driver-pay')}>Payroll</li>
              </ul>
            </li>
            <li>
            Operations
              <ul className={styles.subMenuultimatePage}>
                <li onClick={() => openSection('drivers')}>Drivers</li>
                <li onClick={() => openSection('trucks')}>Fleet</li>
                <li onClick={() => openSection('brokers')}>Brokers</li>
                <li onClick={() => openSection('shippers')}>Shippers</li>
                <li onClick={() => openSection('consignees')}>Consignees</li>
              </ul>
            </li>
            <li>
            Logistics
              <ul className={styles.subMenuultimatePage}>
                <li onClick={() => openSection('load-manager')}>Load Scheduler</li>
                <li onClick={() => openSection('load-finder')}>Load Tracker</li>
              </ul>
            </li>
    
            <li onClick={() => openSection('settings')}>Settings</li>
            <li>
              <button className={styles.buttonultimatePage} onClick={() => openSection('add-new-load')}>
              Load Entry
              </button>
            </li>
            <li>
              
            </li>
            <li>
              <button className={styles.buttonultimatePage} onClick={handleLogout}>Logout</button>
            </li>
          </ul>
        </nav>
      </header>

      <main>
      
        {activeSection === 'drivers' && <Drivers drivers={drivers} setDrivers={setDrivers} />}
        {activeSection === 'trucks' && <Trucks trucks={trucks} setTrucks={setTrucks} />}
        {activeSection === 'brokers' && <Brokers brokers={brokers} setBrokers={setBrokers} />}
        {activeSection === 'shippers' && <Shippers shippers={shippers} setShippers={setShippers} />}
        {activeSection === 'consignees' && <Consignees consignees={consignees} setConsignees={setConsignees} />}
        {activeSection === 'load-manager' && <LoadManager loads={loads} setLoads={setLoads} />}
        {activeSection === 'load-finder' && <LoadFinder loads={loads} />}
        {activeSection === 'accounting-manager' && (
  <AccountingManager 
    driverPays={driverPays} 
    setDriverPays={setDriverPays}  // Asigură-te că ai definit această funcție în UltimatePage
  />
)}
        {activeSection === 'driver-pay' && (
          <DriverPay drivers={drivers} loads={loads} onAddDriverPay={handleAddDriverPay} />
        )}
  {activeSection === 'settings' && (
  <Settings 
    settings={userSettings} 
    updateUserSettings={updateUserSettings} 
    drivers={drivers} 
    handleUpdateDriver={handleUpdateDriver}
    subscriptionType={userSettings.subscriptionType} 
    expirationDate={userSettings.contractEnd}
    
  />
)}

        {activeSection === 'add-new-load' && (
          <AddLoad
            onClose={closeSection}
            onAddLoad={handleAddLoad}
            drivers={drivers}
            trucks={trucks}
            brokers={brokers}
            shippers={shippers}
            consignees={consignees}
   
          />
        )}

   


      </main>
    </div>
  );
};

const Drivers = ({ drivers, setDrivers }) => {
  const [driverData, setDriverData] = useState({
    firstName: '',
    lastName: '',
    truckNumber: '',
    email: '',
    phone: '',
    address: '',
    licenseNumber: '',
    licenseExpiryDate: '',
    lastDrugTestDate: '',
    settlement: '',
    settlementAmount: '', // Camp pentru suma primită la plata
    notes: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDriverData({ ...driverData, [name]: value });
  };

  const handleSubmit = async () => {
    if (editingIndex !== null) {
      const updatedDrivers = [...drivers];
      updatedDrivers[editingIndex] = driverData;
      setDrivers(updatedDrivers);
      setEditingIndex(null);
    } else {
      try {
        const response = await axios.post(`${apiUrl}/drivers`, driverData, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        setDrivers([...drivers, response.data]);
      } catch (error) {
        console.error('Error adding driver:', error);
      }
    }
    setDriverData({
      firstName: '',
      lastName: '',
      truckNumber: '',
      email: '',
      phone: '',
      address: '',
      licenseNumber: '',
      licenseExpiryDate: '',
      lastDrugTestDate: '',
      settlement: '',
      settlementAmount: '', // Resetăm și suma la plată
      notes: '',
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setDriverData(drivers[index]);
  };

  const handleDelete = async (index) => {
    try {
      const driverId = drivers[index]._id;
      await axios.delete(`${apiUrl}/drivers/${driverId}`, {
        withCredentials: true
      });
      const updatedDrivers = drivers.filter((_, i) => i !== index);
      setDrivers(updatedDrivers);
    } catch (error) {
      console.error('Error deleting driver:', error);
    }
  };

  const filteredDrivers = drivers.filter(driver =>
    (driver.firstName && driver.firstName.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (driver.lastName && driver.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className={styles.driversSection}>
      <h3 className={styles.title}>Add or Edit Driver</h3>
      <form className={styles.driverForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>First Name*</label>
            <input
              name="firstName"
              value={driverData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Last Name*</label>
            <input
              name="lastName"
              value={driverData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Truck Number*</label>
            <input
              name="truckNumber"
              value={driverData.truckNumber}
              onChange={handleChange}
              placeholder="Truck Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Email*</label>
            <input
              name="email"
              value={driverData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Phone*</label>
            <input
              name="phone"
              value={driverData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Address*</label>
            <input
              name="address"
              value={driverData.address}
              onChange={handleChange}
              placeholder="Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>License Number*</label>
            <input
              name="licenseNumber"
              value={driverData.licenseNumber}
              onChange={handleChange}
              placeholder="License Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>License Expiry Date*</label>
            <input
              name="licenseExpiryDate"
              type="date"
              value={driverData.licenseExpiryDate}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
    <label>Last Drug Test Date*</label>
    <input
      name="lastDrugTestDate"
      type="date"
      value={driverData.lastDrugTestDate}
      onChange={handleChange}
      required
      className={styles.inputField}
    />
  </div>

  <div className={styles.formGroup}>
  <label>Settlement*</label>
  <select
    name="settlement"
    value={driverData.settlement}
    onChange={handleChange}
    required
    className={styles.selectField}
  >
    <option value="">Select Payment Method</option>
    <option value="Hourly pay">Hourly pay</option>
    <option value="Per mile pay">Per mile pay</option>
    <option value="Fixed salary">Fixed salary</option>
    <option value="Percentage of the load">Percentage of the load</option>
    <option value="Team driver pay">Team driver pay</option>
  </select>
</div>

{/* Afișează câmpurile pentru mile normale și DH dacă este selectată metoda "Per mile pay" */}
{driverData.settlement === 'Per mile pay' && (
  <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label>Enter amount per normal mile ($)*</label>
      <input
        name="normalMileRate"
        type="number"
        step="0.01"
        value={driverData.normalMileRate || ''}
        onChange={handleChange}
        placeholder="Enter amount per normal mile (e.g., 1.25)"
        required
        className={styles.inputField}
      />
    </div>
    <div className={styles.formGroup}>
      <label>Enter amount per DH mile ($)*</label>
      <input
        name="dhMileRate"
        type="number"
        step="0.01"
        value={driverData.dhMileRate || ''}
        onChange={handleChange}
        placeholder="Enter amount per DH mile (e.g., 1.00)"
        required
        className={styles.inputField}
      />
    </div>
  </div>
)}

{/* Afișează câmpul normal pentru alte tipuri de settlement */}
{driverData.settlement && driverData.settlement !== 'Per mile pay' && (
  <div className={styles.formRow}>
    <div className={styles.formGroup}>
      <label>
        {driverData.settlement === 'Percentage of the load'
          ? 'Enter percentage (%)'
          : 'Enter amount ($)'}
        *
      </label>
      <input
        name="settlementAmount"
        type="number"
        step={driverData.settlement === 'Percentage of the load' ? '1' : '0.01'}
        value={driverData.settlementAmount || ''}
        onChange={handleChange}
        placeholder={driverData.settlement === 'Percentage of the load'
          ? 'Enter percentage (e.g., 20 for 20%)'
          : 'Enter amount (e.g., 1000)'}
        required
        className={styles.inputField}
      />
    </div>
  </div>
)}



        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Notes</label>
            <textarea
              name="notes"
              value={driverData.notes}
              onChange={handleChange}
              placeholder="Notes"
              className={styles.textareaField}
            ></textarea>
          </div>
        </div>

        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Driver' : 'Add Driver'}
        </button>
      </form>

      <h3 className={styles.title}>Search Drivers</h3>
      <input
        type="text"
        placeholder="Search by driver name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />

      <h3 className={styles.title}>Existing Drivers</h3>
      <ul className={styles.driverList}>
        {filteredDrivers.map((driver, index) => (
          <li key={index} className={styles.driverListItem}>
            {driver.firstName} {driver.lastName}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>Edit</button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};



// Repetăm același tipar pentru celelalte componente:
// Trucks, Brokers, Shippers, și Consignees

// Componenta Trucks
const Trucks = ({ trucks, setTrucks }) => {
  const [truckData, setTruckData] = useState({
    truckNumber: '',
    miles: '',
    licenses: '',
    settlement: '',
    notes: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTruckData({ ...truckData, [name]: value });
  };

  const handleSubmit = async () => {
    if (editingIndex !== null) {
      // Update existing truck
      const updatedTrucks = [...trucks];
      updatedTrucks[editingIndex] = truckData;
      setTrucks(updatedTrucks);
      setEditingIndex(null);
    } else {
      // Add new truck
      try {
        const response = await axios.post(`${apiUrl}/trucks`, truckData, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        setTrucks([...trucks, response.data]);
      } catch (error) {
        console.error('Error adding truck:', error);
      }
    }
    setTruckData({
      truckNumber: '',
      miles: '',
      licenses: '',
      settlement: '',
      notes: '',
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setTruckData(trucks[index]);
  };

  const handleDelete = async (index) => {
    try {
      const truckId = trucks[index]._id;
      await axios.delete(`${apiUrl}/trucks/${truckId}`,{
        withCredentials: true
      });
      const updatedTrucks = trucks.filter((_, i) => i !== index);
      setTrucks(updatedTrucks);
    } catch (error) {
      console.error('Error deleting truck:', error);
    }
  };

  const filteredTrucks = trucks.filter(truck =>
    truck.truckNumber && truck.truckNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  return (
    <div className={styles.trucksSection}>
      <h3 className={styles.title}>Add or Edit Truck</h3>
      <form className={styles.truckForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Truck Number*</label>
            <input
              name="truckNumber"
              value={truckData.truckNumber}
              onChange={handleChange}
              placeholder="Truck Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Miles*</label>
            <input
              name="miles"
              value={truckData.miles}
              onChange={handleChange}
              placeholder="Miles"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Licenses*</label>
            <input
              name="licenses"
              value={truckData.licenses}
              onChange={handleChange}
              placeholder="Licenses"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
  <label>Settlement*</label>
  <select
    name="settlement"
    value={truckData.settlement}
    onChange={handleChange}
    required
    className={styles.inputField}
  >
    <option value="">Select Settlement</option>
    <option value="Hourly pay">Hourly pay</option>
    <option value="Per mile pay">Per mile pay</option>
    <option value="Fixed salary">Fixed salary</option>
    <option value="Percentage of the load">Percentage of the load</option>
    <option value="Team driver pay">Team driver pay</option>
  </select>
</div>
        </div>
  
        <div className={styles.formGroup}>
          <label>Notes</label>
          <textarea
            name="notes"
            value={truckData.notes}
            onChange={handleChange}
            placeholder="Notes"
            className={styles.textareaField}
          ></textarea>
        </div>
  
        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Truck' : 'Add Truck'}
        </button>
      </form>
  
      <h3 className={styles.title}>Search Trucks</h3>
      <input
        type="text"
        placeholder="Search by truck number"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />
  
      <h3 className={styles.title}>Existing Trucks</h3>
      <ul className={styles.truckList}>
        {filteredTrucks.map((truck, index) => (
          <li key={index} className={styles.truckListItem}>
            {truck.truckNumber}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>
              Edit
            </button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
  
};


// Componenta Brokers
const Brokers = ({ brokers, setBrokers }) => {
  const [brokerData, setBrokerData] = useState({
    brokerName: '',
    address: '',
    email: '',
    phone: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrokerData({ ...brokerData, [name]: value });
  };

  const handleSubmit = async () => {
    if (editingIndex !== null) {
      // Update existing broker
      const updatedBrokers = [...brokers];
      updatedBrokers[editingIndex] = brokerData;
      setBrokers(updatedBrokers);
      setEditingIndex(null);
    } else {
      // Add new broker
      try {
        const response = await axios.post(`${apiUrl}/brokers`, brokerData, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        setBrokers([...brokers, response.data]);
      } catch (error) {
        console.error('Error adding broker:', error);
      }
    }
    setBrokerData({
      brokerName: '',
      address: '',
      email: '',
      phone: '',
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setBrokerData(brokers[index]);
  };

  const handleDelete = async (index) => {
    try {
      const brokerId = brokers[index]._id; // Assuming each broker has an _id field
      await axios.delete(`${apiUrl}/brokers/${brokerId}`, {
        withCredentials: true // Adăugat withCredentials
      });
      const updatedBrokers = brokers.filter((_, i) => i !== index);
      setBrokers(updatedBrokers);
    } catch (error) {
      console.error('Error deleting broker:', error);
    }
  };

  const filteredBrokers = brokers.filter(broker =>
    broker.brokerName && broker.brokerName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  return (
    <div className={styles.brokersSection}>
      <h3 className={styles.title}>Add or Edit Broker</h3>
      <form className={styles.brokerForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Broker Name*</label>
            <input
              name="brokerName"
              value={brokerData.brokerName}
              onChange={handleChange}
              placeholder="Broker Name"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Address*</label>
            <input
              name="address"
              value={brokerData.address}
              onChange={handleChange}
              placeholder="Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Email*</label>
            <input
              name="email"
              value={brokerData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Phone*</label>
            <input
              name="phone"
              value={brokerData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Broker' : 'Add Broker'}
        </button>
      </form>
  
      <h3 className={styles.title}>Search Brokers</h3>
      <input
        type="text"
        placeholder="Search by broker name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />
  
      <h3 className={styles.title}>Existing Brokers</h3>
      <ul className={styles.brokerList}>
        {filteredBrokers.map((broker, index) => (
          <li key={index} className={styles.brokerListItem}>
            {broker.brokerName}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>
              Edit
            </button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
  
};


// Componenta Shippers
const Shippers = ({ shippers, setShippers }) => {
  const [shipperData, setShipperData] = useState({
    shipperName: '',
    shipperAddress: '',
    email: '',
    phone: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setShipperData({ ...shipperData, [name]: value });
  };

  const handleSubmit = async () => {
    if (editingIndex !== null) {
      // Update existing shipper
      const updatedShippers = [...shippers];
      updatedShippers[editingIndex] = shipperData;
      setShippers(updatedShippers);
      setEditingIndex(null);
    } else {
      // Add new shipper
      try {
        const response = await axios.post(`${apiUrl}/shippers`, shipperData, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        setShippers([...shippers, response.data]);
      } catch (error) {
        console.error('Error adding shipper:', error);
      }
    }
    setShipperData({
      shipperName: '',
      shipperAddress: '',
      email: '',
      phone: '',
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setShipperData(shippers[index]);
  };

  const handleDelete = async (index) => {
    try {
      const shipperId = shippers[index]._id;
      await axios.delete(`${apiUrl}/shippers/${shipperId}`, {
        withCredentials: true // Adăugat withCredentials
      });
      const updatedShippers = shippers.filter((_, i) => i !== index);
      setShippers(updatedShippers);
    } catch (error) {
      console.error('Error deleting shipper:', error);
    }
  };
  
  const filteredShippers = shippers.filter(shipper =>
    shipper.shipperName && shipper.shipperName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  
  return (
    <div className={styles.shippersSection}>
      <h3 className={styles.title}>Add or Edit Shipper</h3>
      <form className={styles.shipperForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Shipper Name*</label>
            <input
              name="shipperName"
              value={shipperData.shipperName}
              onChange={handleChange}
              placeholder="Shipper Name"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Address*</label>
            <input
              name="shipperAddress"
              value={shipperData.shipperAddress}
              onChange={handleChange}
              placeholder="Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Email*</label>
            <input
              name="email"
              value={shipperData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Phone*</label>
            <input
              name="phone"
              value={shipperData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Shipper' : 'Add Shipper'}
        </button>
      </form>
  
      <h3 className={styles.title}>Search Shippers</h3>
      <input
        type="text"
        placeholder="Search by shipper name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />
  
      <h3 className={styles.title}>Existing Shippers</h3>
      <ul className={styles.shipperList}>
        {filteredShippers.map((shipper, index) => (
          <li key={index} className={styles.shipperListItem}>
            {shipper.shipperName}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>
              Edit
            </button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}  


// Componenta Consignees
const Consignees = ({ consignees, setConsignees }) => {
  const [consigneeData, setConsigneeData] = useState({
    consigneeName: '',
    consigneeAddress: '',
    email: '',
    phone: '',
  });

  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConsigneeData({ ...consigneeData, [name]: value });
  };

  const handleSubmit = async () => {
    if (editingIndex !== null) {
      // Update existing consignee
      const updatedConsignees = [...consignees];
      updatedConsignees[editingIndex] = consigneeData;
      setConsignees(updatedConsignees);
      setEditingIndex(null);
    } else {
      // Add new consignee
      try {
        const response = await axios.post(`${apiUrl}/consignees`, consigneeData, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        setConsignees([...consignees, response.data]);
      } catch (error) {
        console.error('Error adding consignee:', error);
      }
    }
    setConsigneeData({
      consigneeName: '',
      consigneeAddress: '',
      email: '',
      phone: '',
    });
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setConsigneeData(consignees[index]);
  };

  const handleDelete = async (index) => {
    try {
      const consigneeId = consignees[index]._id;
      await axios.delete(`${apiUrl}/consignees/${consigneeId}`, {
        withCredentials: true // Adăugat withCredentials
      });
      const updatedConsignees = consignees.filter((_, i) => i !== index);
      setConsignees(updatedConsignees);
    } catch (error) {
      console.error('Error deleting consignee:', error);
    }
  };

  const filteredConsignees = consignees.filter(consignee =>
    consignee.consigneeName && consignee.consigneeName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  return (
    <div className={styles.consigneesSection}>
      <h3 className={styles.title}>Add or Edit Consignee</h3>
      <form className={styles.consigneeForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Consignee Name*</label>
            <input
              name="consigneeName"
              value={consigneeData.consigneeName}
              onChange={handleChange}
              placeholder="Consignee Name"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Address*</label>
            <input
              name="consigneeAddress"
              value={consigneeData.consigneeAddress}
              onChange={handleChange}
              placeholder="Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Email*</label>
            <input
              name="email"
              value={consigneeData.email}
              onChange={handleChange}
              placeholder="Email"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Phone*</label>
            <input
              name="phone"
              value={consigneeData.phone}
              onChange={handleChange}
              placeholder="Phone"
              required
              className={styles.inputField}
            />
          </div>
        </div>
  
        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          {editingIndex !== null ? 'Update Consignee' : 'Add Consignee'}
        </button>
      </form>
  
      <h3 className={styles.title}>Search Consignees</h3>
      <input
        type="text"
        placeholder="Search by consignee name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />
  
      <h3 className={styles.title}>Existing Consignees</h3>
      <ul className={styles.consigneeList}>
        {filteredConsignees.map((consignee, index) => (
          <li key={index} className={styles.consigneeListItem}>
            {consignee.consigneeName}
            <button onClick={() => handleEdit(index)} className={styles.editButton}>
              Edit
            </button>
            <button onClick={() => handleDelete(index)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
  
};
const LoadFinder = ({ loads }) => {
  const [search, setSearch] = useState('');
  const [drivers, setDrivers] = useState([]);

  const apiUrl = 'http://localhost:5000/api'; // Asumăm că aceasta este calea API-ului

  // Preluăm lista de șoferi din baza de date când componenta este montată
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/drivers`, { withCredentials: true });
        setDrivers(response.data); // Salvează șoferii în stare
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };

    fetchDrivers();
  }, []);

  // Funcție pentru a găsi numele complet al șoferului pe baza driverId
  const getDriverName = (driverId) => {
    const driver = drivers.find((d) => d._id === driverId);
    return driver ? `${driver.firstName} ${driver.lastName}` : 'Unknown Driver';
  };

  const filteredLoads = loads.filter((load) =>
    load.loadNumber.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={styles.loadFinderSection}>
      <h2 className={styles.title}>Load Tracker</h2>
      <input
        type="text"
        placeholder="Search by Load Number"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInput}
      />
      <ul className={styles.loadList}>
        {filteredLoads.map((load, index) => (
          <li key={index} className={styles.loadItem}>
            <strong>Load #:</strong> {load.loadNumber} <br />
            <strong>Dispatcher:</strong> {load.dispatcher} <br />
            <strong>Broker Name:</strong> {load.brokerName} <br />
            <strong>Rate:</strong> ${load.rate} <br />
            <strong>Driver:</strong> {getDriverName(load.driver)} <br /> {/* Afișăm numele complet al șoferului */}
            <strong>Miles:</strong> {load.miles} <br />
            <strong>DH:</strong> {load.dh} <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

const LoadManager = ({ loads, setLoads }) => {
  const [editingLoad, setEditingLoad] = useState(null);
  const [search, setSearch] = useState('');
  const [drivers, setDrivers] = useState([]);

  const apiUrl = 'http://localhost:5000/api'; // Asumăm că aceasta este calea API-ului

  // Preluăm lista de șoferi din baza de date când componenta este montată
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/drivers`, { withCredentials: true });
        setDrivers(response.data); // Salvează șoferii în stare
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };

    fetchDrivers();
  }, []);

  // Funcție pentru a găsi numele complet al șoferului pe baza driverId
  const getDriverName = (driverId) => {
    const driver = drivers.find((d) => d._id === driverId);
    return driver ? `${driver.firstName} ${driver.lastName}` : 'Unknown Driver';
  };

  // Selectează o încărcătură pentru editare
  const handleSelectLoad = (loadIndex) => {
    setEditingLoad(loads[loadIndex]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingLoad({ ...editingLoad, [name]: value });
  };

  // Salvează modificările
  const handleSave = async () => {
    try {
      if (editingLoad && editingLoad._id) {
        await axios.put(`${apiUrl}/loads/${editingLoad._id}`, editingLoad, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        // Actualizează lista de încărcături în starea locală
        setLoads((prevLoads) =>
          prevLoads.map((load) =>
            load._id === editingLoad._id ? editingLoad : load
          )
        );
        alert('Load updated successfully');
      }
    } catch (error) {
      console.error('Error updating load:', error);
    }
  };

  // Șterge încărcătura
  const handleDelete = async (loadId) => {
    try {
      await axios.delete(`${apiUrl}/loads/${loadId}`, { withCredentials: true });
      setLoads((prevLoads) => prevLoads.filter((load) => load._id !== loadId));
      setEditingLoad(null); // Resetează formularul de editare
      alert('Load deleted successfully');
    } catch (error) {
      console.error('Error deleting load:', error);
    }
  };

  // Căutarea și filtrarea încărcăturilor
  const filteredLoads = loads.filter((load) =>
    load.loadNumber.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={styles.loadManagerSection}>
      <h2 className={styles.title}>Load Scheduler</h2>

      {/* Căutare */}
      <input
        type="text"
        placeholder="Search by Load Number"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInput}
      />

      {/* Afișează lista încărcăturilor filtrate */}
      <ul className={styles.loadList}>
        {filteredLoads.map((load, index) => (
          <li key={index} onClick={() => handleSelectLoad(index)} className={styles.loadItem}>
            <strong>Load #:</strong> {load.loadNumber} <br />
            <strong>Dispatcher:</strong> {load.dispatcher} <br />
            <strong>Broker Name:</strong> {load.brokerName} <br />
            <strong>Rate:</strong> ${load.rate} <br />
            <strong>Driver:</strong> {getDriverName(load.driver)} <br /> {/* Afișăm numele complet al șoferului */}
            <strong>Miles:</strong> {load.miles} <br />
            <strong>DH:</strong> {load.dh} <br />
          </li>
        ))}
      </ul>

      {/* Form pentru editarea încărcăturii selectate */}
      {editingLoad ? (
        <div className={styles.editLoadForm}>
          <label>Load #:</label>
          <input
            name="loadNumber"
            value={editingLoad.loadNumber}
            onChange={handleChange}
            className={styles.inputField}
          />
          <label>Dispatcher:</label>
          <input
            name="dispatcher"
            value={editingLoad.dispatcher}
            onChange={handleChange}
            className={styles.inputField}
          />
          <label>Broker Name:</label>
          <input
            name="brokerName"
            value={editingLoad.brokerName}
            onChange={handleChange}
            className={styles.inputField}
          />
          <label>Rate:</label>
          <input
            name="rate"
            value={editingLoad.rate}
            onChange={handleChange}
            className={styles.inputField}
          />
          <label>Driver:</label>
          <select
            name="driver"
            value={editingLoad.driver}
            onChange={handleChange}
            className={styles.inputField}
          >
            {drivers.map((driver) => (
              <option key={driver._id} value={driver._id}>
                {driver.firstName} {driver.lastName}
              </option>
            ))}
          </select>
          <label>Miles:</label>
          <input
            name="miles"
            value={editingLoad.miles}
            onChange={handleChange}
            className={styles.inputField}
          />
          <label>DH:</label>
          <input
            name="dh"
            value={editingLoad.dh}
            onChange={handleChange}
            className={styles.inputField}
          />
          <button onClick={handleSave} className={styles.saveButton}>Save</button>
          <button onClick={() => handleDelete(editingLoad._id)} className={styles.deleteButton}>Delete</button>
        </div>
      ) : (
        <p>No load selected</p>
      )}
    </div>
  );
};


const AccountingManager = ({ driverPays = [], setDriverPays }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const apiUrl = 'http://localhost:5000/api';

  // Preluăm toți șoferii din baza de date
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/drivers`, { withCredentials: true });
        if (response.status === 200) {
          setDrivers(response.data);
        } else {
          console.error('Error fetching drivers:', response.status);
        }
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };
    fetchDrivers();
  }, []);

  // Filtrare după query de căutare și driverId
  const filteredDriverPays = driverPays.filter(
    (pay) =>
      (!selectedDriver || pay.driverId === selectedDriver) &&
      (!searchQuery || (pay.driverName && pay.driverName.toLowerCase().includes(searchQuery.toLowerCase())))
  );
  const handleDelete = async (payId) => {
    try {
      if (!payId) {
        console.error('Invalid driver pay ID');
        return;
      }

      const config = { withCredentials: true };
      const response = await axios.delete(`${apiUrl}/driverpays/${payId}`, config);

      if (response.status === 200) {
        setDriverPays((prevPays) => prevPays.filter((pay) => pay._id !== payId));
        alert('Driver Pay deleted successfully!');
      } else {
        console.error(`Failed to delete Driver Pay: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting driver pay:', error);
    }
  }
  // Preluare nume șofer după ID-ul selectat
  const selectedDriverDetails = drivers.find((driver) => driver._id === selectedDriver) || {};

  // Calcul total rate, additions, deductions și total pay
  const calculateTotals = (pay) => {
    const totalRate = pay.payDetails.reduce((sum, detail) => sum + detail.rate, 0);
    const recurringAdditionsTotal = (pay.recurringAdditions || []).reduce((sum, add) => sum + parseFloat(add.amount || 0), 0);
    const recurringDeductionsTotal = (pay.recurringDeductions || []).reduce((sum, ded) => sum + parseFloat(ded.amount || 0), 0);
    const totalRateWithAdjustments = totalRate + recurringAdditionsTotal - recurringDeductionsTotal;
    const totalPay = pay.payDetails.reduce((sum, detail) => sum + detail.pay, 0) + recurringAdditionsTotal - recurringDeductionsTotal;

    return { totalRate, totalRateWithAdjustments, totalPay };
  };

  // Generare PDF pentru plățile șoferului
  const generatePDFForAccountingManager = (pay) => {
    if (!pay) return;

    const doc = new jsPDF();
    const driver = drivers.find((driver) => driver._id === pay.driverId);
    const { totalRate, totalRateWithAdjustments, totalPay } = calculateTotals(pay);

    // Adăugăm antetul PDF-ului
    const addHeader = (doc, pageNumber) => {
       {
        
      }
      doc.setFontSize(12);
      doc.text('Settlement', 105, 20, { align: 'center' });

      {
        
      }
    
      doc.text(`Driver: ${driver ? `${driver.firstName} ${driver.lastName}` : 'N/A'}`, 200, 30, { align: 'right' });
      doc.text(`Start Date: ${new Date(pay.startDate).toLocaleDateString() || 'N/A'}`, 200, 40, { align: 'right' });
      doc.text(`End Date: ${new Date(pay.endDate).toLocaleDateString() || 'N/A'}`, 200, 50, { align: 'right' });
      
      doc.text(`Page: ${pageNumber}`, 180, 20);
    };

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(`Page ${pageCount}`, 10, doc.internal.pageSize.height - 10);
    };

    let pageNumber = 1;
    addHeader(doc, pageNumber);

    let startY = 90;

    // Detaliile plății
    const payDetails = Array.isArray(pay.payDetails) ? pay.payDetails : [];
    payDetails.forEach((detail) => {
      if (startY > doc.internal.pageSize.height - 40) {
        doc.addPage();
        pageNumber++;
        addHeader(doc, pageNumber);
        startY = 90;
      }

      doc.autoTable({
        startY: startY,
        head: [['Load #', 'Pickup', 'Delivery', 'Rate', 'Pay', 'Miles', 'DH']],
        body: [
          [detail.loadNumber, detail.pickUpTime, detail.deliveryTime, `$${detail.rate.toFixed(2)}`, `$${detail.pay.toFixed(2)}`, detail.miles, detail.dh],
        ],
        theme: 'grid',
        margin: { top: 10, left: 10, right: 10 }
      });

      startY = doc.lastAutoTable.finalY + 10;
    });

    let lastY = startY + 20;

    if (lastY > doc.internal.pageSize.height - 40) {
      doc.addPage();
      pageNumber++;
      addHeader(doc, pageNumber);
      lastY = 90;
    }

    doc.setFontSize(12);
    doc.text('Driver Payment Breakdown:', 10, lastY);
    lastY += 10;
    doc.setFontSize(10);
    doc.text(`Total Rate: $${totalRate.toFixed(2)}`, 10, lastY);
    lastY += 10;

    const recurringAdditions = Array.isArray(pay.recurringAdditions) ? pay.recurringAdditions : [];
    if (recurringAdditions.length > 0) {
      recurringAdditions.forEach((add) => {
        doc.text(`Recurring Addition (${add.service}): $${parseFloat(add.amount || 0).toFixed(2)}`, 10, lastY);
        lastY += 10;
      });
    } else {
      doc.text('Recurring Additions: $0.00', 10, lastY);
      lastY += 10;
    }

    const recurringDeductions = Array.isArray(pay.recurringDeductions) ? pay.recurringDeductions : [];
    if (recurringDeductions.length > 0) {
      recurringDeductions.forEach((ded) => {
        doc.text(`Recurring Deduction (${ded.service}): -$${parseFloat(ded.amount || 0).toFixed(2)}`, 10, lastY);
        lastY += 10;
      });
    } else {
      doc.text('Recurring Deductions: $0.00', 10, lastY);
      lastY += 10;
    }

    doc.setFontSize(12);
    doc.text(`Total Rate (After Adjustments): $${totalRateWithAdjustments.toFixed(2)}`, 10, lastY);
    lastY += 10;

    if (lastY > doc.internal.pageSize.height - 40) {
      doc.addPage();
      pageNumber++;
      addHeader(doc, pageNumber);
      lastY = 90;
    }

    doc.text(`Total Pay for Loads: $${totalPay.toFixed(2)}`, 10, lastY);
    lastY += 10;

  

    addFooter(doc);
    doc.save('driver_pay.pdf');
  };

  return (
    <div className={styles.accountingManagerSection}>
      <h2 className={styles.title}>Ledger Manager</h2>

      {/* Selectarea șoferului */}
      <select
        value={selectedDriver}
        onChange={(e) => setSelectedDriver(e.target.value)}
        className={styles.selectField}
      >
        <option value="">Select Driver</option>
        {drivers.map((driver) => (
          <option key={driver._id} value={driver._id}>
            {driver.firstName} {driver.lastName}
          </option>
        ))}
      </select>

      {/* Căutare după numele șoferului */}
      <input
        type="text"
        placeholder="Search by Driver Name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className={styles.searchInput}
      />

      {filteredDriverPays.length === 0 ? (
        <p className={styles.noRecords}>No driver pay records found.</p>
      ) : (
        <ul className={styles.payList}>
          {filteredDriverPays.map((pay, index) => {
            const driver = drivers.find((driver) => driver._id === pay.driverId); // Găsește șoferul pe baza driverId
            return (
              <li key={index} className={styles.payListItem}>
                <div>
                  <strong>Driver:</strong> {driver ? `${driver.firstName} ${driver.lastName}` : 'N/A'} <br /> {/* Afișează numele șoferului */}
                  <strong>Total Pay:</strong> ${pay.totalPay.toFixed(2)} <br />
                  <button
                    className={styles.actionButton}
                    onClick={() => generatePDFForAccountingManager(pay)}
                  >
                    Download PDF
                  </button>
                  <button className={styles.deleteButton} onClick={() => handleDelete(pay._id)}>
                    Delete
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};


const DriverPay = ({ drivers, loads, onAddDriverPay }) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredLoads, setFilteredLoads] = useState([]);
  const [selectedLoads, setSelectedLoads] = useState([]);
  const [recurringDeductions, setRecurringDeductions] = useState([{ amount: 0, service: '' }]);
  const [recurringAdditions, setRecurringAdditions] = useState([{ amount: 0, service: '' }]);




  

  const handleDriverSelect = (e) => {
    const selectedDriverId = e.target.value;
    const driver = drivers.find((driver) => driver._id === selectedDriverId);
    setSelectedDriver(driver);
  };

  const handleAddDriverPay = async () => {
    if (!selectedDriver || !startDate || !endDate) {
      console.error('Driver, Start Date or End Date missing');
      return;
    }
  
    const { payDetails, totalRate, totalRateWithAdjustments, totalPay } = calculatePay();
  
    try {
      const response = await axios.post('http://localhost:5000/api/driverpays', {
        // datele care sunt trimise la server
        driverId: selectedDriver._id,
        startDate,
        endDate,
        recurringAdditions,
        recurringDeductions,
        totalPay,
      totalRateWithAdjustments,
      payDetails,

    
    },
    {
      withCredentials: true, // Adaugă withCredentials aici
    }
  );
  
      console.log('Response from server:', response.data); // Log răspunsul pentru a verifica dacă s-a salvat
      if (response.status === 201) {
        console.log('DriverPay saved successfully:', response.data);
      } else {
        console.error('Error saving DriverPay:', response.data);
      }
    } catch (error) {
      console.error('Error adding driver pay:', error);
    }
  };
  
  
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') setStartDate(value);
    else setEndDate(value);
  };

  const searchLoads = () => {
    if (!selectedDriver || !startDate || !endDate) return;

    const filtered = loads.filter((load) => {
      const driverMatch = load.driver?.toString() === selectedDriver._id?.toString();
      const hasPickupDate = load.pickUpTime ? new Date(load.pickUpTime) : null;
      const hasDeliveryDate = load.deliveryTime ? new Date(load.deliveryTime) : null;

      return (
        driverMatch &&
        hasPickupDate &&
        hasDeliveryDate &&
        hasPickupDate >= new Date(startDate) &&
        hasDeliveryDate <= new Date(endDate)
      );
    });

    setFilteredLoads(filtered);
  };

  const handleLoadSelection = (loadNumber) => {
    setSelectedLoads((prevSelected) =>
      prevSelected.includes(loadNumber)
        ? prevSelected.filter((num) => num !== loadNumber)
        : [...prevSelected, loadNumber]
    );
  };

  const calculatePay = () => {
    const payDetails = filteredLoads
      .filter((load) => selectedLoads.includes(load.loadNumber))
      .map((load) => {
        const settlement = selectedDriver.settlement;
        let pay = 0;

        switch (settlement) {
          case 'Hourly pay':
            if (load.hoursWorked) {
              pay = load.hoursWorked * selectedDriver.settlementAmount;
            }
            break;
          case 'Per mile pay':
            const milesPay = load.miles * selectedDriver.settlementAmount;
            const dhPay = load.dh * (selectedDriver.dhMileRate || selectedDriver.settlementAmount);
            pay = milesPay + dhPay;
            break;
          case 'Fixed salary':
            pay = selectedDriver.settlementAmount;
            break;
          case 'Percentage of the load':
            pay = (load.rate * selectedDriver.settlementAmount) / 100;
            break;
          case 'Team driver pay':
            pay = (load.rate * selectedDriver.settlementAmount) / 2;
            break;
          default:
            pay = 0;
            break;
        }

        return {
          loadNumber: load.loadNumber || 'N/A',
          pickUpTime: load.pickUpTime || 'N/A',
          deliveryTime: load.deliveryTime || 'N/A',
          miles: load.miles || 0,
          dh: load.dh || 0,
          rate: load.rate || 0,
          pay,
        };
      });

    const totalRate = payDetails.reduce((sum, detail) => sum + detail.rate, 0);
    const recurringAdditionsTotal = recurringAdditions.reduce((sum, add) => sum + parseFloat(add.amount || 0), 0);
    const recurringDeductionsTotal = recurringDeductions.reduce((sum, ded) => sum + parseFloat(ded.amount || 0), 0);
    const totalRateWithAdjustments = totalRate + recurringAdditionsTotal - recurringDeductionsTotal;

    const totalPay = payDetails.reduce((sum, detail) => {
      switch (selectedDriver.settlement) {
        case 'Hourly pay':
        case 'Per mile pay':
          return sum + detail.pay;
        case 'Fixed salary':
          return sum + selectedDriver.settlementAmount;
        case 'Percentage of the load':
          return sum + (detail.rate * selectedDriver.settlementAmount) / 100;
        case 'Team driver pay':
          return sum + (detail.rate * selectedDriver.settlementAmount) / 2;
        default:
          return sum;
      }
    }, 0);

    return {
      payDetails,
      totalRate,
      totalRateWithAdjustments,
      totalPay,
    };
  };

  const generatePDF = () => {
    const { payDetails, totalRate, totalRateWithAdjustments, totalPay } = calculatePay();
    const doc = new jsPDF();

    const addHeader = (doc, pageNumber) => {
      {
       
      }
      doc.setFontSize(12);
      
      doc.text('Settlemenet', 105, 20, { align: 'center' });
       {
      
      }
      
     
      doc.text(`Driver: ${selectedDriver.firstName} ${selectedDriver.lastName}`, 200, 30, { align: 'right' });
      doc.text(`Start Date: ${startDate}`, 200, 40, { align: 'right' });
      doc.text(`End Date: ${endDate}`, 200, 50, { align: 'right' });
      
      doc.text(`Page: ${pageNumber}`, 180, 20);
    };

    const addFooter = (doc) => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(`Page ${pageCount}`, 10, doc.internal.pageSize.height - 10);
    };

    let pageNumber = 1;
    addHeader(doc, pageNumber);

    let startY = 90;

    payDetails.forEach((detail, index) => {
      if (startY > doc.internal.pageSize.height - 40) {
        doc.addPage();
        pageNumber++;
        addHeader(doc, pageNumber);
        startY = 90;
      }

      doc.autoTable({
        startY: startY,
        head: [['Load #', 'Pickup', 'Delivery', 'Rate', 'Pay', 'Miles', 'DH']],
        body: [
          [detail.loadNumber, detail.pickUpTime, detail.deliveryTime, `$${detail.rate.toFixed(2)}`, `$${detail.pay.toFixed(2)}`, detail.miles, detail.dh],
        ],
        theme: 'grid',
        margin: { top: 10, left: 10, right: 10 },
      });

      startY = doc.lastAutoTable.finalY + 10;
    });

    let lastY = startY + 20;

    if (lastY > doc.internal.pageSize.height - 40) {
      doc.addPage();
      pageNumber++;
      addHeader(doc, pageNumber);
      lastY = 90;
    }

    doc.setFontSize(12);
    doc.text('Driver Payment Breakdown:', 10, lastY);
    lastY += 10;
    doc.setFontSize(10);
    doc.text(`Total Rate: $${totalRate.toFixed(2)}`, 10, lastY);
    lastY += 10;
   // Display each recurring addition with its amount and service
if (recurringAdditions.length > 0) {
  recurringAdditions.forEach((add) => {
    doc.text(`Recurring Addition (${add.service}): $${parseFloat(add.amount || 0).toFixed(2)}`, 10, lastY);
    lastY += 10;
  });
} else {
  doc.text('Recurring Additions: $0.00', 10, lastY);
  lastY += 10;
}

// Display each recurring deduction with its amount and service
if (recurringDeductions.length > 0) {
  recurringDeductions.forEach((ded) => {
    doc.text(`Recurring Deduction (${ded.service}): -$${parseFloat(ded.amount || 0).toFixed(2)}`, 10, lastY);
    lastY += 10;
  });
} else {
  doc.text('Recurring Deductions: $0.00', 10, lastY);
  lastY += 10;
}

    doc.setFontSize(12);
    doc.text(`Total Rate (After Adjustments): $${totalRateWithAdjustments.toFixed(2)}`, 10, lastY);
    lastY += 10;

    if (lastY > doc.internal.pageSize.height - 40) {
      doc.addPage();
      pageNumber++;
      addHeader(doc, pageNumber);
      lastY = 90;
    }

    doc.text(`Total Pay for Loads: $${totalPay.toFixed(2)}`, 10, lastY);
    lastY += 10;

    doc.text('Payment Details for Driver:', 10, lastY);
    lastY += 10;
    doc.setFontSize(10);
    doc.text(`Payment Method: ${selectedDriver.settlement}`, 10, lastY);
    if (selectedDriver.settlement === 'Per mile pay') {
      lastY += 10;
      doc.text(`Per Mile Rate: $${selectedDriver.settlementAmount.toFixed(2)}`, 10, lastY);
      lastY += 10;
      doc.text(`DH Mile Rate: $${selectedDriver.dhMileRate.toFixed(2)}`, 10, lastY);
    } else if (selectedDriver.settlement === 'Fixed salary') {
      lastY += 10;
      doc.text(`Fixed Salary: $${selectedDriver.settlementAmount.toFixed(2)}`, 10, lastY);
    } else if (selectedDriver.settlement === 'Percentage of the load') {
      lastY += 10;
      doc.text(`Percentage of Load: ${selectedDriver.settlementAmount}%`, 10, lastY);
    } else if (selectedDriver.settlement === 'Hourly pay') {
      lastY += 10;
      doc.text(`Hourly Rate: $${selectedDriver.settlementAmount.toFixed(2)}`, 10, lastY);
    }

    addFooter(doc);

    doc.save('driver_pay.pdf');
  };

 

  const addDeduction = () => {
    setRecurringDeductions([...recurringDeductions, { amount: 0, service: '' }]);
  };

  const addAddition = () => {
    setRecurringAdditions([...recurringAdditions, { amount: 0, service: '' }]);
  };

  return (
    <div className={styles.driverPaySection}>
      <h2 className={styles.title}>Payroll</h2>
      <div className={styles.formGroup}>
        <label>Select Driver</label>
        <select onChange={handleDriverSelect} className={styles.selectField}>
          <option value="">Select Driver</option>
          {drivers.map((driver) => (
            <option key={driver._id} value={driver._id}>
              {driver.firstName} {driver.lastName}
            </option>
          ))}
        </select>
      </div>
      {selectedDriver && (
        <div className={styles.driverDetails}>
          <h3 className={styles.subtitle}>
            Driver: {selectedDriver.firstName} {selectedDriver.lastName}
          </h3>
          
          <div className={styles.formRow}>
            <div className={styles.formGroup}>
              <label>Start Date</label>
              <input type="date" name="startDate" value={startDate} onChange={handleDateChange} className={styles.inputField} />
            </div>
            <div className={styles.formGroup}>
              <label>End Date</label>
              <input type="date" name="endDate" value={endDate} onChange={handleDateChange} className={styles.inputField} />
            </div>
          </div>
          <button onClick={searchLoads} className={styles.actionButton}>Search</button>
          {filteredLoads.length > 0 && (
            <div className={styles.loadDetails}>
              <h3 className={styles.subtitle}>Load Details</h3>
              <ul className={styles.loadList}>
                {filteredLoads.map((load, index) => (
                  <li key={index} className={styles.loadItem}>
                    <input
                      type="checkbox"
                      checked={selectedLoads.includes(load.loadNumber)}
                      onChange={() => handleLoadSelection(load.loadNumber)}
                    />
                    <strong>Load #:</strong> {load.loadNumber || 'N/A'} <br />
                    <strong>Pick Up:</strong> {load.pickUpTime || 'N/A'} <br />
                    <strong>Delivery:</strong> {load.deliveryTime || 'N/A'} <br />
                    <strong>Rate:</strong> ${parseFloat(load.rate || 0).toFixed(2)} <br />
                  </li>
                ))}
              </ul>

              <button onClick={calculatePay} className={styles.actionButton}>Calculate</button>
              <div className={styles.recurringSection}>
                <div className={styles.formGroup}>
                  <label>Recurring Deduction</label>
                  {recurringDeductions.map((deduction, index) => (
                    <div key={index}>
                      <input
                        type="number"
                        value={deduction.amount}
                        onChange={(e) => {
                          const updatedDeductions = [...recurringDeductions];
                          updatedDeductions[index].amount = e.target.value;
                          setRecurringDeductions(updatedDeductions);
                        }}
                        placeholder="Amount"
                        className={styles.inputField}
                      />
                      <input
                        type="text"
                        value={deduction.service}
                        onChange={(e) => {
                          const updatedDeductions = [...recurringDeductions];
                          updatedDeductions[index].service = e.target.value;
                          setRecurringDeductions(updatedDeductions);
                        }}
                        placeholder="Service"
                        className={styles.inputField}
                      />
                    </div>
                  ))}
                  <button onClick={addDeduction} className={styles.addButton}>Add Deduction</button>
                </div>
                <div className={styles.formGroup}>
                  <label>Recurring Addition</label>
                  {recurringAdditions.map((addition, index) => (
                    <div key={index}>
                      <input
                        type="number"
                        value={addition.amount}
                        onChange={(e) => {
                          const updatedAdditions = [...recurringAdditions];
                          updatedAdditions[index].amount = e.target.value;
                          setRecurringAdditions(updatedAdditions);
                        }}
                        placeholder="Amount"
                        className={styles.inputField}
                      />
                      <input
                        type="text"
                        value={addition.service}
                        onChange={(e) => {
                          const updatedAdditions = [...recurringAdditions];
                          updatedAdditions[index].service = e.target.value;
                          setRecurringAdditions(updatedAdditions);
                        }}
                        placeholder="Service"
                        className={styles.inputField}
                      />
                    </div>
                  ))}
                  <button onClick={addAddition} className={styles.addButton}>Add Addition</button>
                </div>
              </div>
              <button
  onClick={async () => {
    await handleAddDriverPay(); // Salvează datele în baza de date
    generatePDF(); // După ce datele sunt salvate, generează PDF-ul
  }}
  className={styles.actionButton}
>
  Save and Preview
</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const AddLoad = ({
  onClose,
  onAddLoad,
  drivers,
  trucks,

  brokers,
  shippers,
  consignees,
}) => {
  const [loadData, setLoadData] = useState({
    loadNumber: "",
    brokerName: "",
    brokerAddress: "",
    rate: "",
    shipperName: "",
    shipperAddress: "",
    pickUpTime: "",
    consigneeName: "",
    consigneeAddress: "",
    deliveryTime: "",
    dispatcher: "",
    driver: "",
    truck: "",
    status: "",
    miles: "",
    dh: "",
    notes: "",
  });

  const apiKey = "AIzaSyANTFgVt0YuYOHPbMRNYO3r09Zu0j73yXY"; // Inlocuiește cu cheia ta

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoadData((prevState) => ({
      ...prevState,
      [name]: value || "",
    }));
  };

  // Efect pentru calculul milelor imediat ce ambele adrese sunt disponibile
  useEffect(() => {
    if (loadData.shipperAddress && loadData.consigneeAddress) {
      calculateMiles(loadData.shipperAddress, loadData.consigneeAddress);
    }
  }, [loadData.shipperAddress, loadData.consigneeAddress]); // Ascultăm schimbările pentru shipper și consignee

  const handleBrokerSelect = (e) => {
    const selectedBrokerName = e.target.value;
    const selectedBroker = brokers.find(
      (b) => b.brokerName === selectedBrokerName
    );
    setLoadData((prevState) => ({
      ...prevState,
      brokerName: selectedBrokerName,
      brokerAddress: selectedBroker ? selectedBroker.address : "",
    }));
  };

  const handleShipperSelect = (e) => {
    const selectedShipperName = e.target.value;
    const selectedShipper = shippers.find(
      (s) => s.shipperName === selectedShipperName
    );
    const newShipperAddress = selectedShipper ? selectedShipper.shipperAddress : "";

    setLoadData((prevState) => ({
      ...prevState,
      shipperName: selectedShipperName,
      shipperAddress: newShipperAddress,
    }));
  };

  const handleConsigneeSelect = (e) => {
    const selectedConsigneeName = e.target.value;
    const selectedConsignee = consignees.find(
      (c) => c.consigneeName === selectedConsigneeName
    );
    const newConsigneeAddress = selectedConsignee ? selectedConsignee.consigneeAddress : "";

    setLoadData((prevState) => ({
      ...prevState,
      consigneeName: selectedConsigneeName,
      consigneeAddress: newConsigneeAddress,
    }));
  };

  const calculateMiles = async (shipperAddress, consigneeAddress) => {
    if (!shipperAddress || !consigneeAddress) return;

    const url = `http://localhost:5000/api/calculate-distance?origins=${encodeURIComponent(
      shipperAddress
    )}&destinations=${encodeURIComponent(consigneeAddress)}&apiKey=${apiKey}`;

    try {
      const response = await axios.get(url);
      const data = response.data;

      if (data.rows && data.rows[0].elements && data.rows[0].elements[0].distance) {
        const distanceInMeters = data.rows[0].elements[0].distance.value;
        const miles = distanceInMeters * 0.000621371; // Convert meters to miles

        setLoadData((prevState) => ({
          ...prevState,
          miles: miles.toFixed(2),
        }));
      } else {
        console.error("No distance data available");
      }
    } catch (error) {
      console.error("Error calculating miles:", error);
    }
  };

  const handleSubmit = async () => {
    if (
      !loadData.loadNumber ||
      !loadData.brokerName ||
      !loadData.brokerAddress ||
      !loadData.driver ||
      !loadData.status ||
      !loadData.shipperName ||
      !loadData.shipperAddress ||
      !loadData.consigneeName ||
      !loadData.consigneeAddress
    ) {
      alert("Please fill in all required fields");
      return;
    }

    try {
      await saveBrokersShippersConsignees();
      await axios.post("http://localhost:5000/api/loads", loadData, {
        withCredentials: true,
      });

      setLoadData({
        loadNumber: "",
        dispatcher: "",
        brokerName: "",
        brokerAddress: "",
        rate: "",
        equipment: "",
        driver: "",
        truck: "",
        status: "",
        shipperName: "",
        shipperAddress: "",
        pickUpTime: "",
        consigneeName: "",
        consigneeAddress: "",
        deliveryTime: "",
        miles: "",
        dh: "",
        notes: "",
      });

      console.log("Load added successfully");
    } catch (error) {
      console.error("Error adding load:", error);
      alert("There was an error saving the load: " + error.message);
    }
  };
  const saveBrokersShippersConsignees = async () => {
    try {
      const brokerExists = brokers.some(
        (broker) => broker.brokerName === loadData.brokerName
      );
      if (!brokerExists) {
        await axios.post(
          "http://localhost:5000/api/brokers",
          {
            brokerName: loadData.brokerName,
            brokerAddress: loadData.brokerAddress,
          },
          { withCredentials: true }
        );
      }

      const shipperExists = shippers.some(
        (shipper) => shipper.shipperName === loadData.shipperName
      );
      if (!shipperExists) {
        await axios.post(
          "http://localhost:5000/api/shippers",
          {
            shipperName: loadData.shipperName,
            shipperAddress: loadData.shipperAddress,
          },
          { withCredentials: true }
        );
      }

      const consigneeExists = consignees.some(
        (consignee) => consignee.consigneeName === loadData.consigneeName
      );
      if (!consigneeExists) {
        await axios.post(
          "http://localhost:5000/api/consignees",
          {
            consigneeName: loadData.consigneeName,
            consigneeAddress: loadData.consigneeAddress,
          },
          { withCredentials: true }
        );
      }

      console.log("All brokers, shippers, and consignees saved successfully");
    } catch (error) {
      console.error("Error saving brokers, shippers, or consignees:", error);
      throw error;
    }
  };

  const handleCancel = () => {
    onClose();
  };
  return (
    <div className={styles.sectionContainer}>
      <h2 className={styles.title}>Add New Load</h2>
      <form className={styles.loadForm}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Load Number*</label>
            <input
              name="loadNumber"
              value={loadData.loadNumber}
              onChange={handleChange}
              placeholder="Load Number"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Dispatcher*</label>
            <input
              name="dispatcher"
              value={loadData.dispatcher}
              onChange={handleChange}
              placeholder="Dispatcher"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Broker Name*</label>
            <input
              list="brokers-list"
              name="brokerName"
              value={loadData.brokerName}
              onChange={handleBrokerSelect}
              placeholder="Broker Name"
              required
              className={styles.inputField}
            />
            <datalist id="brokers-list">
              {brokers.map((broker) => (
                <option key={broker._id} value={broker.brokerName} />
              ))}
            </datalist>
          </div>
          <div className={styles.formGroup}>
            <label>Broker Address*</label>
            <input
              name="brokerAddress"
              value={loadData.brokerAddress}
              onChange={handleChange}
              placeholder="Broker Address"
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Rate (in dollars)*</label>
            <input
              name="rate"
              value={loadData.rate}
              onChange={handleChange}
              placeholder="Rate"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Equipment</label>
            <select
              name="equipment"
              value={loadData.equipment}
              onChange={handleChange}
              className={styles.selectField}
            >
              <option value="">Select Equipment</option>
              <option value="Dry Van">Dry Van</option>
              <option value="53 Van">53 Van</option>
              <option value="Reefer">Reefer</option>
              <option value="Flat Bed">Flat Bed</option>
              <option value="Low Boy">Low Boy</option>
              <option value="Tanker">Tanker</option>
              <option value="Container Hauler">Container Hauler</option>
            </select>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Driver*</label>
            <select
              name="driver"
              value={loadData.driver}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Driver</option>
              {drivers.map((driver, index) => (
                <option key={index} value={driver._id}>
                  {driver.firstName} {driver.lastName}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup}>
            <label>Truck</label>
            <select
              name="truck"
              value={loadData.truck}
              onChange={handleChange}
              className={styles.selectField}
            >
              <option value="">Select Truck</option>
              {trucks.map((truck, index) => (
                <option key={index} value={truck._id}>
                  {truck.truckNumber}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Status*</label>
            <select
              name="status"
              value={loadData.status}
              onChange={handleChange}
              required
              className={styles.selectField}
            >
              <option value="">Select Status</option>
              <option value="In Progress">In Progress</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Shipper Name*</label>
            <input
              list="shippers-list"
              name="shipperName"
              value={loadData.shipperName}
              onChange={handleShipperSelect}
              placeholder="Shipper Name"
              required
              className={styles.inputField}
            />
            <datalist id="shippers-list">
              {shippers.map((shipper) => (
                <option key={shipper._id} value={shipper.shipperName} />
              ))}
            </datalist>
          </div>
          <div className={styles.formGroup}>
            <label>Shipper Address*</label>
            <input
              name="shipperAddress"
              value={loadData.shipperAddress}
              onChange={handleChange}
              placeholder="Shipper Address"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Pick-up Time*</label>
            <input
              name="pickUpTime"
              type="datetime-local"
              value={loadData.pickUpTime}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Consignee Name*</label>
            <input
              list="consignees-list"
              name="consigneeName"
              value={loadData.consigneeName}
              onChange={handleConsigneeSelect}
              placeholder="Consignee Name"
              required
              className={styles.inputField}
            />
            <datalist id="consignees-list">
              {consignees.map((consignee) => (
                <option key={consignee._id} value={consignee.consigneeName} />
              ))}
            </datalist>
          </div>
          <div className={styles.formGroup}>
            <label>Consignee Address*</label>
            <input
              name="consigneeAddress"
              value={loadData.consigneeAddress}
              onChange={handleChange}
              placeholder="Consignee Address"
              required
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>Delivery Time*</label>
            <input
              name="deliveryTime"
              type="datetime-local"
              value={loadData.deliveryTime}
              onChange={handleChange}
              required
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Miles</label>
            <input
              name="miles"
              value={loadData.miles}
              onChange={handleChange}
              placeholder="Miles"
              className={styles.inputField}
            />
          </div>
          <div className={styles.formGroup}>
            <label>DH</label>
            <input
              name="dh"
              value={loadData.dh}
              onChange={handleChange}
              placeholder="DH"
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.formGroup}>
          <label>Notes</label>
          <textarea
            name="notes"
            value={loadData.notes}
            onChange={handleChange}
            placeholder="Notes"
            className={styles.textareaField}
          ></textarea>
        </div>

        <button type="button" onClick={handleSubmit} className={styles.submitButton}>
          Add New Load
        </button>
        <button type="button" onClick={handleCancel} className={styles.closeBtn}>
          Cancel
        </button>
      </form>

    
    </div>
  );
};

const Settings = ({ settings, updateUserSettings, drivers = [],  }) => {
  const [updatedSettings, setUpdatedSettings] = useState({
    companyName: '',
    companyAddress: '',
    firstName: '',
    lastName: '',
    mcNumber: '',
    phone: '',
    email: '',
    subscriptionType: '',
    contractStart: '',
    contractEnd: '',
    notes: ''
  });

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
 const [subscriptionInfo, setSubscriptionInfo] = useState({
    paymentType: '',
    expirationDate: ''
  });

  useEffect(() => {
    if (settings) {
      setUpdatedSettings({
        companyName: settings.companyName || '',
        companyAddress: settings.companyAddress || '',
        firstName: settings.firstName || '',
        lastName: settings.lastName || '',
        mcNumber: settings.mcNumber || '',
        phone: settings.phone || '',
        email: settings.email || '',
        subscriptionType: settings.subscriptionType || '',
        contractStart: settings.contractStart || '',
        contractEnd: settings.contractEnd || '',
        notes: settings.notes || ''
      });

      setSubscriptionInfo({
        paymentType: settings.paymentType || 'Manual',
        expirationDate: settings.expirationDate || 'Not Available'
      });
    }
  }, [settings]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedSettings({ ...updatedSettings, [name]: value });
  };

  const handleSaveSettings = () => {
    if (password && password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    updateUserSettings({ ...updatedSettings, password });
  };

  
  

  

  return (
    <div className={styles.settingsSection}>
      <h2 className={styles.title}>Settings</h2>
      <form className={styles.settingsForm}>
        <label>Company Name</label>
        <input
          name="companyName"
          value={updatedSettings.companyName || ''}
          onChange={handleChange}
          placeholder="Company Name"
          className={styles.inputField}
        />
  
        <label>Company Address</label>
        <input
          name="companyAddress"
          value={updatedSettings.companyAddress || ''}
          onChange={handleChange}
          placeholder="Company Address"
          className={styles.inputField}
        />
  
        <label>First Name</label>
        <input
          name="firstName"
          value={updatedSettings.firstName || ''}
          onChange={handleChange}
          placeholder="First Name"
          className={styles.inputField}
        />
  
        <label>Last Name</label>
        <input
          name="lastName"
          value={updatedSettings.lastName || ''}
          onChange={handleChange}
          placeholder="Last Name"
          className={styles.inputField}
        />
  
        <label>MC Number</label>
        <input
          name="mcNumber"
          value={updatedSettings.mcNumber || ''}
          onChange={handleChange}
          placeholder="MC Number"
          className={styles.inputField}
        />
  
        <label>Phone Number</label>
        <input
          name="phone"
          value={updatedSettings.phone || ''}
          onChange={handleChange}
          placeholder="Phone Number"
          className={styles.inputField}
        />
  
        <label>Email</label>
        <input
          name="email"
          value={updatedSettings.email || ''}
          onChange={handleChange}
          placeholder="Email"
          className={styles.inputField}
        />
  
        <label>New Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New Password"
          className={styles.inputField}
        />
  
        <label>Confirm Password</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm Password"
          className={styles.inputField}
        />
  
        <button type="button" onClick={handleSaveSettings} className={styles.submitButton}>
          Save Settings
        </button>
      </form>
  
      <h3 className={styles.subtitle}>Subscription</h3>
      <p>Payment Type: {subscriptionInfo.paymentType}</p>
      <p>Expiration Date: {subscriptionInfo.expirationDate}</p>
  
      <button onClick={() => window.location.href = '/faq'} className={styles.actionButton}>
        FAQ
      </button>
    </div>
  );
  
};

export default BasicPage;